import Breadcrumb, { BreadcrumbProps } from 'components/Breadcrumb/Breadcrumb';
import CMSSlot from 'components/CMS/CMSSlot/CMSSlot';
import InfoLinks from 'components/InfoLinks/InfoLinks';
import PlpHeader from 'components/Layout/Plp/PlpHeader';
import PlpResults from 'components/Layout/Plp/Results/PlpResults';
import Pagination from 'components/Pagination/Pagination';
import SearchNoResults from 'components/Search/SearchNoResults/SearchNoResults';
import { ANALYTIC_EVENTS } from 'constants/analytics';
import useRouter from 'hooks/useRouter';
import useUpdateCart from 'hooks/useUpdateCart';
import { useIntl } from 'react-intl';
import { Brand } from 'types/Brand';
import { CMSSearchPage } from 'types/CMS';
import { Category } from 'types/Category';
import { FacetDefinition, FacetSort } from 'types/Facet';
import { BillboardPlacement } from 'types/Kamino';
import type { InfoLinks as InfoLinksType } from 'types/Links';
import { Pagination as PaginationType } from 'types/Pagination';
import { PcConfigurationPart } from 'types/PcConfiguration';
import { Product, RealTimeInfo } from 'types/Product';
import { Button } from 'ui/Button/Button';
import { Text } from 'ui/Text/Text';
import { cn } from 'utils/cn';
import PlpFilters from './PlpFilters';

interface AddToCartOverrideParams {
  analyticsList?: string;
  position?: number;
  product: Product;
}
interface PlpLayoutProps {
  addToCartLabel?: string;
  addToCartOverride?: (params: AddToCartOverrideParams) => Promise<void>;
  billboard?: BillboardPlacement;
  brand?: Brand;
  breadcrumb?: BreadcrumbProps;
  category?: Category;
  cmsSearchPage?: CMSSearchPage;
  createPageLink: (page: number, label: string, className?: string) => JSX.Element;
  facets?: FacetDefinition[];
  infoLinks?: InfoLinksType;
  pagination?: PaginationType;
  pcConfigurationPart?: PcConfigurationPart;
  products?: Product[];
  promotedBrands?: Brand[];
  realtimeInfoProducts?: RealTimeInfo[];
  sorts?: FacetSort[];
}

const PlpLayout = ({
  addToCartLabel,
  addToCartOverride,
  billboard,
  brand,
  breadcrumb,
  category,
  cmsSearchPage,
  createPageLink,
  facets = [],
  infoLinks,
  pagination,
  pcConfigurationPart,
  products = [],
  promotedBrands,
  realtimeInfoProducts,
  sorts,
}: PlpLayoutProps) => {
  const router = useRouter();
  const { formatMessage } = useIntl();
  const { addToCart: handleAddToCart } = useUpdateCart();

  const { currentPage = 0, pageSize = 24, totalPages = 0, totalResults = 0 } = pagination || {};
  const hasFacets = facets?.length;
  const categoryCode = category?.code;
  const secondDescription = category?.secondDescription ?? cmsSearchPage?.secondDescription;

  // TODO: Move to hooks and make generalised
  const addToCart = async (product: Product, analyticsList?: string, position?: number) => {
    if (addToCartOverride) {
      return addToCartOverride({ analyticsList, position, product });
    }
    await handleAddToCart({
      analyticsList,
      event: ANALYTIC_EVENTS.ADD_TO_CART_PLP,
      position,
      product,
    });
  };

  return (
    <div>
      {breadcrumb && <Breadcrumb {...breadcrumb} className={cn('my-6', breadcrumb.className)} />}

      {products?.length ? (
        <div className="lg:grid lg:grid-cols-12 lg:grid-rows-[auto,1fr] lg:gap-x-8 lg:pb-4">
          <PlpHeader
            className="mb-6 md:mb-3 lg:col-span-9"
            category={category}
            cmsSearchPage={cmsSearchPage}
            brand={brand}
            pcConfigurationPart={pcConfigurationPart}
          />

          <div className="relative col-span-3 row-span-2 row-start-1 hidden lg:block">
            <Button
              className="absolute left-[-9999px] m-2 focus-visible:relative focus-visible:left-auto"
              variant="secondary"
              onClick={() => {
                const plpResultsElement = typeof window !== 'undefined' && document.getElementById('plp-results');
                if (plpResultsElement) {
                  plpResultsElement.focus({ preventScroll: true });
                }
              }}
            >
              {formatMessage({ id: 'category_skip_to_searchresults' })}
            </Button>
            <PlpFilters
              className="rounded-lg bg-accent-20"
              categoryCode={categoryCode}
              facets={facets}
              sorts={sorts}
              totalResults={totalResults}
            />
          </div>

          <div
            className={cn(hasFacets ? 'lg:col-span-9' : 'lg:col-span-12', 'focus-border flex flex-col gap-4')}
            id="plp-results"
            tabIndex={0}
          >
            <PlpResults
              billboard={billboard}
              addToCartLabel={addToCartLabel}
              l3Category={category}
              products={products}
              promotedBrands={promotedBrands}
              onAddToCart={addToCart}
              realtimeInfoProducts={realtimeInfoProducts}
              totalResults={totalResults}
              sorts={sorts}
            />

            {/* TODO: Refactor in Phase 2 */}
            {pagination && (
              <Pagination
                amountOfResults={totalResults || 0}
                createPageLink={createPageLink}
                currentPage={currentPage}
                pageSize={pageSize}
                totalPages={totalPages}
              />
            )}

            {/* TODO: Phase 2 add advice links */}

            {secondDescription && <Text className="py-4" html={secondDescription} />}

            {infoLinks && <InfoLinks infoLinks={infoLinks} />}

            {cmsSearchPage && <CMSSlot cmsPage={cmsSearchPage} position="bottom" />}
          </div>
        </div>
      ) : (
        <SearchNoResults
          buttons={[
            {
              children: formatMessage({
                defaultMessage: ' ',
                id: 'search_back_button',
              }),
              onClick: () => router?.back?.(),
              variant: 'outlined',
            },
          ]}
          title={formatMessage(
            { id: 'search_no_results_text' },
            {
              query: category?.name,
            },
          )}
        />
      )}
    </div>
  );
};
export default PlpLayout;
