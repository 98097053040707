import Billboard from 'components/Billboard/Billboard';
import { ProductCard } from 'components/Product/ProductCard';
import {
  PlpSearchResult,
  isPlpBillboard,
  isPlpContentTile,
  isPlpLeaderBoard,
  isPlpProduct,
  isPlpPromotedBrands,
} from 'constants/product';
import useProductCard from 'hooks/useProductCard';
import dynamic from 'next/dynamic';
import { memo, useMemo, useState } from 'react';
import { Brand } from 'types/Brand';
import { Category } from 'types/Category';
import { CompareGroup } from 'types/Compare';
import { FacetSort } from 'types/Facet';
import { BillboardPlacement } from 'types/Kamino';
import { Product, RealTimeInfo } from 'types/Product';
import { cn } from 'utils/cn';
import { NUMBER_OF_PAGE_RESULTS } from '../../../../constants/search';
import { enrichSearchResults } from '../../../../utils/productUtil';
import PlpResultsContentTile from './PlpResultsContentTile';
import PlpResultsHeader from './PlpResultsHeader';
import PlpResultsLeaderboard from './PlpResultsLeaderboard';

const BrandLogosSection = dynamic(() => import('../../../Brands/BrandLogosSection/BrandLogosSection'));

interface PlpResultsProps {
  addToCartLabel?: string;
  billboard?: BillboardPlacement;
  className?: string;
  l3Category?: Category;
  onAddToCart?: (product: Product, analyticsList?: string, position?: number) => Promise<void>;
  products: Product[];
  promotedBrands?: Brand[];
  realtimeInfoProducts?: RealTimeInfo[];
  sorts?: FacetSort[];
  totalResults?: number;
}

type PlpResultProps = {
  addToCartLabel?: string;
  categoryCode?: string;
  compareGroup?: CompareGroup;
  hasBillboard: boolean;
  hasLeaderBoard: boolean;
  nextItem?: PlpSearchResult;
  onAddToCart?: (product: Product, analyticsList?: string, position?: number) => Promise<void>;
  realtimeInfoProducts?: RealTimeInfo[];
  searchResult: PlpSearchResult;
};

const PlpResult = ({
  addToCartLabel,
  categoryCode,
  compareGroup,
  hasBillboard,
  hasLeaderBoard,
  nextItem,
  onAddToCart,
  realtimeInfoProducts,
  searchResult,
}: PlpResultProps) => {
  const [loading, setLoading] = useState(false);
  const { mapProductCardProps } = useProductCard();

  if (isPlpProduct(searchResult)) {
    const showDivider = nextItem && isPlpProduct(nextItem);
    const realtimeInfo = realtimeInfoProducts?.find((realTimeProduct) => realTimeProduct.code === searchResult.code);

    const productCardProps = mapProductCardProps({
      compareGroup,
      onAddToCart: async () => {
        try {
          setLoading(true);
          await onAddToCart?.(searchResult);
        } finally {
          setLoading(false);
        }
      },
      product: searchResult,
      realtimeInfo,
    });

    const baseProps = {
      ...productCardProps,
      buttonLabel: addToCartLabel ?? productCardProps.buttonLabel,
      loading,
      product: searchResult,
      showRealTimeInfoModal: true,
    };

    return (
      <>
        <ProductCard {...baseProps} className={showDivider ? 'border-b pb-6 md:border-none' : ''} />
      </>
    );
  }

  if (isPlpContentTile(searchResult)) {
    return <PlpResultsContentTile contentTile={searchResult} className="md:col-start-3 md:row-start-1" />;
  }

  if (isPlpBillboard(searchResult)) {
    return <Billboard className="col-span-full md:row-start-2" placement={searchResult} />;
  }

  if (isPlpLeaderBoard(searchResult)) {
    const rowStartDesktop = hasBillboard ? 'md:row-start-4' : 'md:row-start-2';
    return (
      <PlpResultsLeaderboard
        leaderBoards={searchResult.leaderBoards}
        className={cn('col-span-full', rowStartDesktop)}
      />
    );
  }

  if (isPlpPromotedBrands(searchResult)) {
    const rowStartDesktop =
      hasBillboard && hasLeaderBoard
        ? 'md:row-start-6'
        : hasBillboard || hasLeaderBoard
          ? 'md:row-start-4'
          : 'md:row-start-2';
    return <BrandLogosSection className={cn('col-span-full', rowStartDesktop)} categoryCode={categoryCode} />;
  }

  return null;
};

const PlpResults = ({
  addToCartLabel,
  billboard,
  className,
  l3Category,
  onAddToCart,
  products,
  promotedBrands,
  realtimeInfoProducts,
  sorts,
  totalResults,
}: PlpResultsProps) => {
  const hasBillboard = !!billboard;
  const hasLeaderBoard = !!l3Category?.leaderBoards?.length;

  const searchResults = useMemo(
    () =>
      enrichSearchResults({
        billboard,
        contentTiles: l3Category?.plpContentTiles,
        leaderBoards: l3Category?.leaderBoards,
        products,
        promotedBrands,
      }),
    [l3Category?.plpContentTiles, l3Category?.leaderBoards, products, promotedBrands, billboard],
  );

  const hideLastTile =
    l3Category?.plpContentTiles?.length &&
    products?.length &&
    Object.values(NUMBER_OF_PAGE_RESULTS).includes(products.length);

  return (
    <section className={className}>
      <PlpResultsHeader hasResults={!!searchResults.length} totalResults={totalResults} sortOptions={sorts} />
      <div className={cn('grid grid-cols-1 gap-3 md:grid-cols-3 md:gap-6')}>
        {searchResults?.map((searchResult, index) => {
          if (index === searchResults.length - 1 && hideLastTile) {
            return null;
          }

          return (
            <PlpResult
              key={`${searchResult.type}-${index}`}
              categoryCode={l3Category?.code}
              compareGroup={l3Category?.compareGroup}
              searchResult={searchResult}
              hasLeaderBoard={hasLeaderBoard}
              hasBillboard={hasBillboard}
              addToCartLabel={addToCartLabel}
              nextItem={searchResults[index + 1]}
              onAddToCart={onAddToCart}
              realtimeInfoProducts={realtimeInfoProducts}
            />
          );
        })}
      </div>
    </section>
  );
};

export default memo(PlpResults);
